import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Card } from "react-bootstrap"
import TextCarousel from "../components/homepage/TextCarousel"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />
        <TextCarousel />
        <Container className=''>
          <Row>
            <Col md={6}>
              <Card className='no-anim '>
                <Card.Body className="card-content card-card">
                  <h2>Web development</h2>
                  <hr />
                  <p>We strictly adhere to our own best principles and best practice but understand that every business is unique and operates within its own niche. Our services are tailored to your specific requirements.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className='no-anim'>
                <Card.Body className="card-content card-card">
                  <h2>Consultation</h2>
                  <hr />
                  <p>We work with you to deliver optimal outcomes for your project. No matter how large or small, as your business grows so will its need for an evolving digital presence. We provide a modern and up-to-date approach to the challenges of business in this age.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} >
              <div className="glitch-container">

              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
