import React from "react"
import styled from "styled-components"
import { Carousel } from "react-bootstrap"

const StyledCarousel = styled(Carousel)`
  z-index:99;
  min-height: 140px;
  padding: 1rem 1rem 1rem 1rem;
  @media (min-width: 1000px) {
    padding: 1.5rem 1rem 2rem 1rem
  }
  margin-bottom: 2rem;
  .carousel-item {
    text-align:center;
  }
  h3 {
    z-index:99;
    color: #fff;
    }
  p {
    color: #fff;
  }
  a {
    color: var(--textLink)
  }
`

const TextCarousel = () => {
  return (
    <div className="styled-carousel fixed-height-200 mb-5 pt-3">
      <h1 className="mt-2 text-center text-white text-uppercase font-weight-bold">Did you know?</h1>
      <StyledCarousel controls={false} fade={true} indicators={false} interval={3500} pause="hover">
        <Carousel.Item>
          <h3>Google has listed website speed as a key factor in ranking pages.</h3>
          <p>Source <a href='https://moz.com/learn/seo/page-speed'>MOZ</a></p>
        </Carousel.Item>
        <Carousel.Item>
          <h3>47% of people expect a web page to load in two seconds or less.</h3>
          <p>Source <a href='https://www.webfx.com/blog/marketing/user-experience-matters-marketing/'>WebFX</a></p>
        </Carousel.Item>
        <Carousel.Item>
          <h3>70% of small businesses don’t have a call-to-action button.</h3>
          <p>Source <a href='https://www.sweor.com/firstimpressions'>SWEOR</a></p>
        </Carousel.Item>
        <Carousel.Item>
          <h3>A website’s bounce rate is directly connected to the loading speed.</h3>
          <p>Source <a href='https://www.webalive.com.au/web-design-statistics/'>Web Alive</a></p>
        </Carousel.Item>
        <Carousel.Item>
          <h3>52.2% of the global web traffic is now mobile.</h3>
          <p>Source <a href='https://www.statista.com/statistics/241462/global-mobile-phone-website-traffic-share/'>Statista</a></p>
        </Carousel.Item>
        <Carousel.Item>
          <h3>60% of users will distrust a company with a non-responsive website.</h3>
          <p>Source <a href='https://www.magnet4blogging.net/web-design-stats-and-facts/'>Magnet4blogging</a></p>
        </Carousel.Item>
      </StyledCarousel>
    </div>
  )
}

export default TextCarousel
